import { Fragment } from 'react';
import { graphicDesignData } from '../data';
import './GraphicDesignProjects.css';

const GraphicDesignProjects = () => {
	return (
		<Fragment>
			<div className="graphic-design-project-container">
				{graphicDesignData.map((item) => {
					return (
						<div key={item.name} className="project-content">
							<div className="project-header">
								<div className="bar" />
								<h4>{item.name}</h4>
							</div>
							<div className="project">
								<img className="project-thumbnail" src={item.img} alt={item.name} />
								<p className="project-desc">{item.desc}</p>
							</div>
						</div>
					);
				})}
			</div>
		</Fragment>
	);
};

export default GraphicDesignProjects;
