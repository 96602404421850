import { Fragment } from 'react';
import Header from './components/header/Header';
import Breadcrumb from './components/breadcrumb/Breadcrumb';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import FrontendProjects from './components/frontend projects/FrontendProjects';
import UXUIProjects from './components/ux-ui projects/UXUIProjects';
import GraphicDesignProjects from './components/graphic design projects/GraphicDesignProjects';
import Testimonials from './components/testimonials/Testimonials';
import Footer from './components/footer/Footer';
import './global-styles.css';

const App = () => {
	return (
		<Router>
			<Switch>
				<Fragment>
					<Header />
					<Breadcrumb />
					<Route exact path="/">
						<FrontendProjects />
					</Route>
					<Route exact path="/ux-ui">
						<UXUIProjects />
					</Route>
					<Route exact path="/graphic-design">
						<GraphicDesignProjects />
					</Route>
					<Testimonials />
					<Footer />
				</Fragment>
			</Switch>
		</Router>
	);
};

export default App;
