import { Fragment } from 'react';
import './Footer.css';

const Footer = () => {
	return (
		<Fragment>
			<div className="footer-container">
				<div className="footer-content">
					<p className="copyright">&#169; 2021 Bryan Portfolio. All Rights Reserved.</p>
				</div>
			</div>
		</Fragment>
	);
};

export default Footer;
