import { Fragment, useState } from 'react';
import './Breadcrumb.css';
import { Link } from 'react-router-dom';

const Breadcrumb = () => {
	const [ color, setColor ] = useState();
	const [ color2, setColor2 ] = useState();
	const [ color3, setColor3 ] = useState();

	function handleColor(color1, color2, color3) {
		setColor({ color: color1 });
		setColor2({ color: color2 });
		setColor3({ color: color3 });
	}

	function handleFrontendDevTab() {
		handleColor('var(--blue)', 'var(--black)');
	}

	function handleUXUITab() {
		handleColor('var(--black)', 'var(--blue)');
	}

	function handleGraphicDesignTab() {
		handleColor('var(--black)', 'var(--black)', 'var(--blue)');
	}

	return (
		<Fragment>
			<div className="breadcrumb-container">
				<div className="tab-container">
					<Link onClick={handleFrontendDevTab} className="frontend-dev-tab" to="/">
						<h4 style={color}>Frontend Developer</h4>
					</Link>
					<Link onClick={handleUXUITab} className="ux-ui-tab" to="/ux-ui">
						<h4 style={color2}>UX/UI</h4>
					</Link>
					<Link onClick={handleGraphicDesignTab} className="graphic-design-tab" to="/graphic-design">
						<h4 style={color3}>Graphic Design</h4>
					</Link>
				</div>
			</div>
		</Fragment>
	);
};

export default Breadcrumb;
