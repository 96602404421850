import { Fragment } from 'react';
import { headerData } from '../data';
import { FaQuoteLeft } from 'react-icons/fa';
import './Header.css';

const Header = () => {
	return (
		<Fragment>
			<div className="background-img" />
			<div className="header-container">
				<div className="header-content">
					<h1 className="header-name">{headerData.name}</h1>
					{/* <div className="header-profile-img-container">
						<img className="profile-img" src={headerData.img} alt="profile pic" />
					</div> */}
					<div className="header-quote-container">
						<FaQuoteLeft className="header-left-quote" />
						<h1 className="header-quote">{headerData.quote}</h1>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Header;
