import { Fragment } from 'react';
import { frontendDevData } from '../data';
import './FrontendProjects.css';

const FrontendProjects = () => {
	return (
		<Fragment>
			<div className="frontend-project-container">
				{frontendDevData.map((item) => {
					return (
						<div key={item.name} className="project-content">
							<div className="project-header">
								<div className="bar" />
								<h4>{item.name}</h4>
							</div>
							<div className="project">
								<a href={item.url} target="_blank" rel="noreferrer">
									<img className="project-thumbnail" src={item.img} alt={item.name} />
								</a>
								<p className="project-desc">{item.desc}</p>
							</div>
						</div>
					);
				})}
			</div>
		</Fragment>
	);
};

export default FrontendProjects;
