import stayfitThumbnail from '../images/stayfit_showcase.jpg';
import countryshackThumbnail from '../images/country_shack_showcase.jpg';
import petmoThumbnail from '../images/petmo_showcase.jpg';
import freshmartThumbnail from '../images/fresh_mart_showcase.jpg';
import profilePic from '../images/profile_pic.jpg';
import elephantDesign from '../images/elephant_square_design.jpg';
import plantDesign from '../images/plant_design.jpg';
import puzzlePuppiesDesign from '../images/puzzle_puppies_design.jpg';
import twoDesign from '../images/two_design.jpg';

export const headerData = {
	name: "Bryan's Portfolio",
	quote: 'My vision through creativity',
	img: profilePic
};

export const projectTabs = [ 'Frontend Developer', 'UX/UI' ];

export const frontendDevData = [
	{
		name: 'StayFit',
		img: stayfitThumbnail,
		desc:
			'StayFit is a gym application built using ReactJS. This application allows users to navigate StayFit apparel and use Google Maps for store locations.',
		url: 'https://stayfitgym.netlify.app/'
	},
	{
		name: 'Country Shack',
		img: countryshackThumbnail,
		desc:
			'Country Shack is a restaurant application built using ReactJS. This application allows users to explore the food menu via smooth scrolling, providing a great user experience.',
		url: 'https://countryshack.netlify.app/'
	}
];
export const uxuiData = [
	{
		name: 'Petmo',
		img: petmoThumbnail,
		desc:
			'Petmo is a doggy daycare website mockup designed to promote customers to signup for new pet services. The splash background design brings out the website contrast enhancing the overall UI.',
		url: 'https://xd.adobe.com/view/4a84a3c6-74ad-4d3b-ba3a-71ef212fe42c-1fd7/'
	},
	{
		name: 'Fresh Mart',
		img: freshmartThumbnail,
		desc:
			'Fresh Mart is a unique landing page mockup regarding healthy meals. This design contains an image carousel that automatically loops through various popular meals to promote. The UI is simple but eye-catching, allowing ease of use.',
		url: 'https://xd.adobe.com/view/940d66ae-ce22-464c-a99f-d3f6526ed3cc-c15e/'
	}
];

export const graphicDesignData = [
	{
		name: 'WildLife ',
		img: elephantDesign,
		desc: 'The wildlife design inspired by Africa Elephants gives the user two unique aspects via grayscale.'
	},
	{
		name: 'Plant Essentials',
		img: plantDesign,
		desc:
			'The plant design showcases great contrast via typography. The background shape also adds a unique look to the design overall.'
	},
	{
		name: 'Two',
		img: twoDesign,
		desc:
			'The two design logo is very vivid and clear to read due to the typography and background contrast. The wavy background effect flows smoothly through the text to showcase a two-tone look.'
	},
	{
		name: 'Puzzle Design',
		img: puzzlePuppiesDesign,
		desc: 'The puzzle design is a custom puzzle piece used to display small playful puppies creatively.'
	}
];

export const testimonialData = [
	{
		clientMessage: 'Bryan was great to work with and completed everything I needed done.',
		clientName: 'Shelby R.',
		stars: 5
	},
	{
		clientMessage: 'Great to work with. Extremely positive and flexible. Excellent communication.',
		clientName: 'Michael O.',
		stars: 5
	}
];
