import { Fragment } from 'react';
import { FaQuoteLeft } from 'react-icons/fa';
import { AiFillStar } from 'react-icons/ai';
import { testimonialData } from '../data';
import './Testimonials.css';

const Testimonials = () => {
	return (
		<Fragment>
			<div className="testimonial-outer-container">
				<div className="testimonial-container">
					<h4 className="testimonial-header">See What People Are Saying</h4>
					<div className="testimonial-content">
						{testimonialData.map((item) => {
							return (
								<div key={item.clientName}>
									<FaQuoteLeft className="testimonial-left-quote" />
									<div className="client-content-container">
										<p className="client-message">{item.clientMessage}</p>
										<p className="client-name">{item.clientName}</p>
										<div className="stars-container">
											<AiFillStar className="client-star-review" />
											<AiFillStar className="client-star-review" />
											<AiFillStar className="client-star-review" />
											<AiFillStar className="client-star-review" />
											<AiFillStar className="client-star-review" />
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Testimonials;
